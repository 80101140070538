import primitives from './primitives'

export const typography = () => {
    /**
     * {
     *  fontSize: '10px',
     *  fontWeight: 300,
     *  lineHeight: '14px'
     * }
     */
    const ParagraphXXSmall = {
        fontFamily: primitives.primaryFontFamily,
        fontSize: '10px',
        fontWeight: 300,
        lineHeight: '14px'
    }
    /**
     * {
     *  fontSize: '12px',
     *  fontWeight: 300,
     *  lineHeight: '16px'
     * }
     */
    const ParagraphXSmall = {
        fontSize: '12px',
        fontWeight: 300,
        lineHeight: '16px'
    }
    /**
     * {
     *  fontSize: '14px',
     *  fontWeight: 300,
     *  lineHeight: '20px'
     * }
     */
    const ParagraphSmall = {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '20px'
    }
    /**
     * {
     *  fontSize: '16px',
     *  fontWeight: 300,
     *  lineHeight: '22px'
     * }
     */
    const ParagraphMedium = {
        fontSize: '16px',
        fontWeight: 300,
        lineHeight: '22px'
    }
    /**
     * {
     *  fontSize: '18px',
     *  fontWeight: 300,
     *  lineHeight: '28px'
     * }
     */
    const ParagraphLarge = {
        fontSize: '18px',
        fontWeight: 300,
        lineHeight: '28px'
    }
    /**
     * {
     *  fontSize: '20px',
     *  fontWeight: 300,
     *  lineHeight: '30px'
     * }
     */
    const ParagraphXLarge = {
        fontSize: '20px',
        fontWeight: 300,
        lineHeight: '30px'
    }
    /**
     * {
     *  fontSize: '22px',
     *  fontWeight: 300,
     *  lineHeight: '32px'
     * }
     */
    const ParagraphXXLarge = {
        fontSize: '22px',
        fontWeight: 300,
        lineHeight: '32px'
    }
    /**
     * {
     *  fontSize: '12px',
     *  fontWeight: 500,
     *  lineHeight: '16px'
     * }
     */
    const LabelXSmall = {
        fontFamily: primitives.primaryFontFamily,
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '16px'
    }
    /**
     * {
     *  fontSize: '14px',
     *  fontWeight: 500,
     *  lineHeight: '16px'
     * }
     */
    const LabelSmall = {
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px'
    }
    /**
     * {
     *  fontSize: '16px',
     *  fontWeight: 500,
     *  lineHeight: '22px'
     * }
     */
    const LabelMedium = {
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '22px'
    }
    /**
     * {
     *  fontSize: '18px',
     *  fontWeight: 500,
     *  lineHeight: '28px'
     * }
     */
    const LabelLarge = {
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '28px'
    }
    /**
     * {
     *  fontSize: '20px',
     *  fontWeight: 700,
     *  lineHeight: '28px'
     * }
     */
    const HeadingXSmall = {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '30px'
    }
    /**
     * {
     *  fontSize: '22px',
     *  fontWeight: 700,
     *  lineHeight: '30px'
     * }
     */
    const HeadingSmall = {
        fontSize: '22px',
        fontWeight: 700,
        lineHeight: '32px'
    }
    /**
     * {
     *  fontSize: '24px',
     *  fontWeight: 700,
     *  lineHeight: '36px'
     * }
     */
    const HeadingMedium = {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '36px'
    }
    /**
     * {
     *  fontSize: '26px',
     *  fontWeight: 700,
     *  lineHeight: '38px'
     * }
     */
    const HeadingLarge = {
        fontSize: '26px',
        fontWeight: 700,
        lineHeight: '38px'
    }
    /**
     * {
     *  fontSize: '28px',
     *  fontWeight: 700,
     *  lineHeight: '440x'
     * }
     */
    const HeadingXLarge = {
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '40px'
    }
    /**
     * {
     *  fontSize: '40px',
     *  fontWeight: 700,
     *  lineHeight: '56px',
        letterSpacing: '-.1ex'
     * }
     */
    const HeadingXXLarge = {
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '56px',
        letterSpacing: '-.1ex'
    }
    /**
     * {
     *  fontSize: '48px',
     *  fontWeight: 700,
     *  lineHeight: '66px',
        letterSpacing: '-.1ex'
     * }
     */
    const DisplayXSmall = {
        fontSize: '48px',
        fontWeight: 700,
        lineHeight: '66px',
        letterSpacing: '-.1ex'
    }
    /**
     * {
     *   fontSize: '56px',
     *   fontWeight: 700,
     *   lineHeight: '70px',
        letterSpacing: '-.1ex'
     * }
     */
    const DisplaySmall = {
        fontSize: '56px',
        fontWeight: 700,
        lineHeight: '70px',
        letterSpacing: '-.1ex'
    }
    /**
     * {
     *  fontSize: '64px',
     *  fontWeight: 700,
     *  lineHeight: '78px',
        letterSpacing: '-.1ex'
     * }
     */
    const DisplayMedium = {
        fontSize: '64px',
        fontWeight: 700,
        lineHeight: '78x',
        letterSpacing: '-.1ex'
    }
    /**
     * {
     *  fontSize: '96px',
     *  fontWeight: 700,
     *  lineHeight: '110px',
        letterSpacing: '-.1ex'
     * }
     */
    const DisplayLarge = {
        fontSize: '96px',
        fontWeight: 700,
        lineHeight: '110px',
        letterSpacing: '-.1ex'
    }

    return {
        /** {@link LabelXSmall} */
        font100: LabelXSmall,
        /** {@link ParagraphXSmall} */
        font150: ParagraphXSmall,
        /** {@link LabelSmall} */
        font200: LabelSmall,
        /** {@link ParagraphSmall} */
        font250: ParagraphSmall,
        /** {@link LabelMedium} */
        font300: LabelMedium,
        /** {@link ParagraphMedium} */
        font350: ParagraphMedium,
        /** {@link LabelLarge} */
        font400: LabelLarge,
        /** {@link ParagraphLarge} */
        font450: ParagraphLarge,
        /** {@link HeadingXSmall} */
        font550: HeadingXSmall,
        /** {@link HeadingSmall} */
        font650: HeadingSmall,
        /** {@link HeadingMedium} */
        font750: HeadingMedium,
        /** {@link HeadingLarge} */
        font850: HeadingLarge,
        /** {@link HeadingXLarge} */
        font950: HeadingXLarge,
        /** {@link HeadingXXLarge} */
        font1050: HeadingXXLarge,
        /** {@link DisplayXSmall} */
        font1150: DisplayXSmall,
        /** {@link DisplaySmall} */
        font1250: DisplaySmall,
        /** {@link DisplayMedium} */
        font1350: DisplayMedium,
        /** {@link DisplayLarge} */
        font1450: DisplayLarge,
        /** {@link ParagraphXXSmall} */
        ParagraphXXSmall,
        /** {@link ParagraphXSmall} */
        ParagraphXSmall,
        /** {@link ParagraphSmall} */
        ParagraphSmall,
        /** {@link ParagraphMedium} */
        ParagraphMedium,
        /** {@link ParagraphLarge} */
        ParagraphLarge,
        /** {@link ParagrapXLarge} */
        ParagraphXLarge,
        /** {@link ParagraphXXLarge} */
        ParagraphXXLarge,
        /** {@link LabelXSmall} */
        LabelXSmall,
        /** {@link LabelSmall} */
        LabelSmall,
        /** {@link LabelMedium} */
        LabelMedium,
        /** {@link LabelLarge} */
        LabelLarge,
        /** {@link HeadingXSmall} */
        HeadingXSmall,
        /** {@link HeadingSmall} */
        HeadingSmall,
        /** {@link HeadingMedium} */
        HeadingMedium,
        /** {@link HeadingLarge} */
        HeadingLarge,
        /** {@link HeadingXLarge} */
        HeadingXLarge,
        /** {@link HeadingXXLarge} */
        HeadingXXLarge,
        /** {@link DisplayXSmall} */
        DisplayXSmall,
        /** {@link DisplaySmall} */
        DisplaySmall,
        /** {@link DisplayMedium} */
        DisplayMedium,
        /** {@link DisplayLarge} */
        DisplayLarge
    }
}

export default { typography }
