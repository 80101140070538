export const scales = (scale: number) => ({
    /** scale * 0 px */
    'space-0': scale * 0 + 'px',
    /** scale * 1 px */
    'space-1': scale * 1 + 'px',
    /** scale * 2 px */
    'space-2': scale * 2 + 'px',
    /** scale * 4 px */
    'space-4': scale * 4 + 'px',
    /** scale * 6 px */
    'space-6': scale * 6 + 'px',
    /** scale * 8 px */
    'space-8': scale * 8 + 'px',
    /** scale * 10 px */
    'space-10': scale * 10 + 'px',
    /** scale * 12 px */
    'space-12': scale * 12 + 'px',
    /** scale * 16 px */
    'space-16': scale * 16 + 'px',
    /** scale * 20 px */
    'space-20': scale * 20 + 'px',
    /** scale * 24 px */
    'space-24': scale * 24 + 'px',
    /** scale * 26 px */
    'space-26': scale * 26 + 'px',
    /** scale * 32 px */
    'space-32': scale * 32 + 'px',
    /** scale * 36 px */
    'space-36': scale * 36 + 'px',
    /** scale * 40 px */
    'space-40': scale * 40 + 'px',
    /** scale * 44 px */
    'space-44': scale * 44 + 'px',
    /** scale * 48 px */
    'space-48': scale * 48 + 'px',
    /** scale * 56 px */
    'space-56': scale * 56 + 'px',
    /** scale * 64 px */
    'space-64': scale * 64 + 'px',
    /** scale * 72 px */
    'space-72': scale * 72 + 'px',
    /** scale * 80 px */
    'space-80': scale * 80 + 'px',
    /** scale * 96 px */
    'space-96': scale * 96 + 'px',
    /** scale * 112 px */
    'space-112': scale * 112 + 'px',
    /** scale * 120 px */
    'space-120': scale * 120 + 'px',
    /** scale * 128 px */
    'space-128': scale * 128 + 'px',
    /** scale * 144 px */
    'space-144': scale * 144 + 'px',
    /** scale * 160 px */
    'space-160': scale * 160 + 'px',
    /** scale * 192 px */
    'space-192': scale * 192 + 'px',
    /** scale * 208 px */
    'space-208': scale * 208 + 'px',
    /** scale * 224 px */
    'space-224': scale * 224 + 'px',
    /** scale * 256 px */
    'space-256': scale * 256 + 'px',
    /** scale * 288 px */
    'space-288': scale * 288 + 'px',
    /** scale * 320 px */
    'space-320': scale * 320 + 'px',
    /** scale * 384 px */
    'space-384': scale * 384 + 'px'
})

export const sizing = (scale: number) => ({
    ...scales(scale),
    /** scale * 2 px */
    scale0: scale * 2 + 'px',
    /** scale * 4 px */
    scale100: scale * 4 + 'px',
    /** scale * 6 px */
    scale200: scale * 6 + 'px',
    /** scale * 8 px */
    scale300: scale * 8 + 'px',
    /** scale * 10 px */
    scale400: scale * 10 + 'px',
    /** scale * 12 px */
    scale500: scale * 12 + 'px',
    /** scale * 14 px */
    scale550: scale * 14 + 'px',
    /** scale * 16 px */
    scale600: scale * 16 + 'px',
    /** scale * 18 px */
    scale650: scale * 18 + 'px',
    /** scale * 20 px */
    scale700: scale * 20 + 'px',
    /** scale * 22 px */
    scale750: scale * 22 + 'px',
    /** scale * 24 px */
    scale800: scale * 24 + 'px',
    /** scale * 28 px */
    scale850: scale * 28 + 'px',
    /** scale * 32 px */
    scale900: scale * 32 + 'px',
    /** scale * 36 px */
    scale950: scale * 36 + 'px',
    /** scale * 40 px */
    scale1000: scale * 40 + 'px',
    /** scale * 48 px */
    scale1200: scale * 48 + 'px',
    /** scale * 56 px */
    scale1400: scale * 56 + 'px',
    /** scale * 64 px */
    scale1600: scale * 64 + 'px',
    /** scale * 96 px */
    scale2400: scale * 96 + 'px',
    /** scale * 128 px */
    scale3200: scale * 128 + 'px',
    /** scale * 192 px */
    scale4800: scale * 192 + 'px'
})
